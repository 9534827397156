import React, { Fragment, useState } from "react";
import Filter from "../Filter/Filter";
import { BreakPointTheme } from "../materialUI/breakpointTheme";
import { ThemeProvider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import classes from "./CitiesList.module.css";

const favouriteCitiesList = [
  { value: "1", name: "Королёв"},
  { value: "5", name: "Щёлково"},
  { value: "26", name: "Москва" },
  { value: "80", name: "Симферополь" },
];

//Сбор городов из разметки сайта

let citiesListFromSite = [];
let selectedCityName = '';

const citiesSelectElement = document.getElementById("plugin_siteregion");

if (citiesSelectElement) {
  const citiesOptions = citiesSelectElement.querySelectorAll("option");
  citiesOptions.forEach((cityOption) => {
    const cityObject = {
      // value: cityOption.getAttribute("data-url"),
      value: cityOption.getAttribute("value"),
      name: cityOption.innerHTML,
      label: cityOption.innerHTML,
    };
    citiesListFromSite.push(cityObject);
  });
} else {
  citiesListFromSite = favouriteCitiesList;
}

if (citiesSelectElement) {
  selectedCityName =
    citiesSelectElement.options[citiesSelectElement.selectedIndex].text;
} else {
  selectedCityName = "Королёв";
}

const CitiesList = () => {
  //Фильтрация городов при первом запуске
  const initCitiesSort = citiesListFromSite.sort((city1, city2) => city1.name.toLowerCase().localeCompare(city2.name.toLowerCase()));

  //Отслеживание, использовался ли фильтр
  const [listIsNotFiltered, setListIsNotFiltered] = useState(true);

  //Стейт городов после фильтрации по букве и сортировки по убыванию
  const [filteredCitiesList, setFiltereCitiesList] = useState(initCitiesSort);

  //Фильтрация общего списка городов по букве
  function filterArrayByLetter(letter = "А") {
    const arrayFilteredByLetter = citiesListFromSite.filter((city) => city.name.toLowerCase().startsWith(letter.toLowerCase()));

    setListIsNotFiltered(false);

    return arrayFilteredByLetter;
  }

  //Фильтрация общего списка городов по названию
  function filterArrayByName(name) {
    const arrayFilteredByName = citiesListFromSite.filter((city) => city.name.toLowerCase().includes(name.toLowerCase()));

    setListIsNotFiltered(false);

    return arrayFilteredByName;
  }

  //После нажатия в компоненте фильтра фильтруем массив с городами по букве
  function filterCitiesAfterClick(letter) {
    //Отфильтрованный массив помещаем в стейт для рендера в блоке
    setFiltereCitiesList(filterArrayByLetter(letter));
  }

  //После ввода в компоненте фильтра фильтруем массив с городами по названию
  function filterCitiesAfterInput(name) {
    //Отфильтрованный массив помещаем в стейт для рендера в блоке
    setFiltereCitiesList(filterArrayByName(name));
  }

  //Разделение массива городов на колонки (макс 16 городов в одной)
  function splitArray(array, n) {
    let [...arr] = array;
    let res = [];

    while (arr.length) {
      res.push(arr.splice(0, n));
    }

    return res;
  }

  //Результат деления массива городов на колонки
  const columns = splitArray(filteredCitiesList, 1000);

  //Строка в колонке
  const Row = ({ rows }) => {
    return rows.map((city, key) => (
      <li key={key} onClick={regionChange} value={city.value} className={selectedCityName == city.name ? classes.cities_list__favourite_selected : ''}>
        <span>{city.name}</span>
        <span className="item__select">Выбрать</span>
      </li>
    ));
  };

  //Колонка
  const Column = (props) => {
    return (
      <ul className={classes.column}>
        <Row rows={props.rows}></Row>
      </ul>
    );
  };

  //Отправка запроса и переключение на региональный сайт при клике на город
  const regionChange = async (event) => {
    const url = event.target.closest('li').getAttribute("value");
    const id = event.target.closest('li').value;

    try {
      const response = await fetch("/getDomain/" + id);

      if (!response.ok) {
        throw new Error("Данные не отправлены");
      }

      const data = await response.json();

      console.log(data.data);
      window.location.href = "https://" + data.data + window.location.pathname;
    } catch (error) {
      console.log(error);
      setListIsNotFiltered(false);
    }
  };

  let citiesContent = <p>Воспользуйтесь поиском городов или выберите первую букву.</p>;

  if (listIsNotFiltered && filteredCitiesList.length > 0) {
    citiesContent = <p>Воспользуйтесь поиском или выберите ближайший город</p>;
  }

  if (!listIsNotFiltered && filteredCitiesList.length === 0) {
    citiesContent = <p>Не найдено городов.</p>;
  }

  if (filteredCitiesList.length > 0) {
    citiesContent = columns.map((rows, key) => <Column key={key} rows={rows}></Column>);
  }

  return (
    <Fragment>
      <ThemeProvider theme={BreakPointTheme}>
        <TextField
        className={classes.autocomplete__wrapper}
        id="combo-box-demo"
        placeholder="Введите название ..."
        onChange={(event) => {
          filterCitiesAfterInput(event.target.value);
        }}
        ></TextField>
        {/* <Autocomplete
          className={classes.autocomplete__wrapper}
          disablePortal
          id="combo-box-demo"
          options={citiesListFromSite}
          noOptionsText={"Не найдено городов"}
          sx={{ maxWidth: 500 }}
          renderInput={(params) => <TextField {...params} label="Выберите город" />}
          renderOption={(props, option) => (
            <li {...props} onClick={regionChange} value={option.value}>
              {option.name}
            </li>
          )}
        ></Autocomplete> */}
      </ThemeProvider>

      {/* <Filter onFilterClick={filterCitiesAfterClick}></Filter> */}
      <div className={classes.city_current}>
          Ваш город: <span>{selectedCityName}</span>
        </div>
      <div className={classes.cities_list__wrapper}>
        <div className={classes.cities_list__main + (listIsNotFiltered ? '' : ' ' + classes.cities_list__main + '--filtered')}>{citiesContent}</div>
      </div>
    </Fragment>
  );
};

export default CitiesList;
